<template>
  <div class="products">
    <Breadcrumb :items="breadcrumb" :key="category.categoryId" />

    <v-container fluid class="pt-sm-0">
      <ProductListGrid
        v-bind:parentCategoryId="category.categoryId"
        :key="category.categoryId"
        :category="category"
        @productsCount="updateCount"
        position="category_med"
        :footerText="footerText"
      >
        <template #category-title>
          <category-title
            class="mt-2 mt-sm-0"
            v-if="category"
            :category="category"
            :showSub="true"
          />
        </template>
        <template #category-block-position-1>
          <category-block
            :target="category"
            position="position1"
            class="category-block category-block-1"
            :container="false"
          />
        </template>
        <template #category-block-position-3>
          <category-block
            :target="category"
            position="position3"
            class="category-block category-block-3"
            :container="false"
          />
        </template>
        <template #category-block-position-4>
          <category-block
            :target="category"
            position="position4"
            class="category-block category-block-4"
            :container="false"
          />
        </template>
        <template #category-block-position-5>
          <category-block
            :target="category"
            position="position5"
            class="category-block category-block-5"
            :container="false"
          />
        </template>
      </ProductListGrid>
    </v-container>

    <category-block
      :target="category"
      position="position2"
      class="category-block category-block-2"
    />
  </div>
</template>
<style lang="scss">
.products {
  .category-title {
    margin-top: -17px;
  }
}
</style>
<script>
import ProductListGrid from "@/components/product/ProductListGrid";
import Breadcrumb from "@/components/navigation/Breadcrumb";
import CategoryTitle from "./CategoryTitle.vue";

import categoryMixins from "~/mixins/category";

export default {
  name: "CategoryMed",
  mixins: [categoryMixins],
  components: {
    ProductListGrid,
    Breadcrumb,
    CategoryTitle
  },
  data() {
    return { count: -1 };
  },
  computed: {
    footerText() {
      return this.$ebsn.meta(
        this.category,
        "metaData.category_info.FOOTER_TEXT",
        null
      );
    }
  },
  methods: {
    updateCount(count) {
      this.count = count;
    }
  },
  metaInfo() {
    let text = this.category.name;
    if (this.breadcrumb.length > 0) {
      text = this.breadcrumb[this.breadcrumb.length - 2].text;
    }
    return {
      title: this.$ebsn.meta(
        this.category,
        "metaData.category_seo.SEO_TITLE",
        this.category ? this.category.name : ""
      ),
      meta: [
        {
          vmid: "description",
          name: "description",
          content: this.$ebsn.meta(
            this.category,
            "metaData.category_seo.SEO_DESCRIPTION",
            this.$t("meta.category.secondAndThirdLevel.description", [
              this.category.name,
              text
            ])
          )
        }
      ]
    };
  }
};
</script>
